<template>
  <div>
    <div class=" m-2 pt-2 pb-2 bg-secondary text-light">Customer Management</div>
      <div class="row">
          <div class="col">
              <div class="input-group">
                <div class="w-50"><input type="text" class="form-control form-control-sm" placeholder="Enter customer name" aria-label="City"></div>
                <button class="btn btn-outline-primary btn-sm ms-2 " v-on:click="listCustomers">Get</button>
                <button class="btn btn-outline-primary btn-sm ms-2"  v-on:click="newCustomer">New Customer</button>
              </div>
          </div>
      </div>
      <div class="row">
          <div v-if="showscreen==screens.SEARCH_RESULTS" class="mt-2"> 
            <div class="table-responsive">
              <table class="table table-striped table-hover caption-top">
                  <caption>Search Reasults</caption>
                  <thead>
                      <tr>
                          <th class="w-25">Name</th>
                          <th class="w-50">Address</th>
                          <th class="w-25"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="text-start" v-for="(customer) in customers" v-bind:key="customer.id">
                          <td>{{customer.name}}</td>
                          <td>{{customer.address}}</td>
                          <td>
                              <div class="col">
                              <button type="button" class="ms-2 btn btn-sm btn-outline-primary" v-on:click="showDetails(customer)"><i class="bi bi-card-text"></i></button>
                              
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
          </div>
          <div v-if="showscreen==screens.CUSTOMER_DETAILS" class="mt-2">
             <div class="card">
                 <div class="card-header">
                     <h3>Customer Details</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" >Name</span>
                                    <input type="text" class="form-control" v-model="curCustomer.name" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > GST</span>
                                    <input type="text" class="form-control" v-model="curCustomer.gst" placeholder="Gst">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text"> Address</span>
                                    <textarea class="form-control" rows="5" ></textarea>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" >Supplier No</span>
                                    <input type="text" class="form-control" v-model="curCustomer.supplierNo" placeholder="Supplier Number">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="table-responsive">
                                    <table class="table caption-top table-sm">
                                        <caption>Contacts</caption>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <td>Designation</td>
                                                <td>Phone</td>
                                                <td>Email</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="text-start" v-for="contact in curCustomer.contacts" v-bind:key="contact.id">
                                                <td>{{contact.name}}</td>
                                                <td>{{contact.designation}}</td>
                                                <td>{{contact.phoneno}}</td>
                                                <td>{{contact.email}}</td>
                                                <td><button class="btn btn-outline-primary btn-sm" v-on:click="deleteContactOfCustomer"><i class="bi bi-person-dash"></i></button></td>
                                            </tr>
                                        </tbody>
                                        <tfoot >
                                            <button v-on:click="addContactToCustomer" class="btn  btn-primary btn-sm ms-2 mt-2"><i class="bi bi-person-plus"></i></button>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div class="col">
                                <div class="table-responsive">
                                    
                                </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2" v-on:click="updateCustomerDetails">Save</button>
                     <button class="ps-2 btn  btn-primary btn-sm ms-2" v-on:click="gotoSearchResults">Close</button>
                 </div>
             </div>
          </div>
          <div v-if="showscreen==screens.ADD_CONTACT" class="mt-2">
              <newContactComponent @save-record="contactSavedEventHandler" @cancel="cancelEventHandler" v-bind:title="'Customer Contact Management'"> </newContactComponent>
          </div>
          <div v-if="showscreen==screens.NEW_CUSTOMER" class="mt-2">
             <div class="card">
                 <div class="card-header">
                     <h3>New Customer</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Name</span>
                                    <input type="text" class="form-control" v-model="curCustomer.name" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group col-6">
                                    <span class="input-group-text" > GST Number</span>
                                    <input type="text" class="form-control" v-model="curCustomer.gst" placeholder="GST Number">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text"> Address</span>
                                    <textarea class="form-control" rows="5" v-model="curCustomer.address" ></textarea>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" >Supplier No</span>
                                    <input type="text" class="form-control" v-model="curCustomer.supplierNo"  placeholder="Supplier Number">
                                </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2" v-on:click="addCustomer">Save</button>
                     <button class="btn  btn-primary btn-sm ms-2" v-on:click="gotoCustomerHome">Cancel</button>
                 </div>
             </div>
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import newContactComponent from '@/components/newContact.vue'
import {states} from '@/services/appService.js'
import {listCustomers,getCustomerTemplate,addCustomer} from '@/services/customerService.js'

export default {
  name: 'CustomerMgmt',
  components:{
      newContactComponent
  },
  data(){
      return {
          showscreen:0,
          customers:[],
          products:[],
          states:[],
          curCustomer:{},
          screens:{CUSTOMER_HOME:0,SEARCH_RESULTS:1,NEW_CUSTOMER:2,CUSTOMER_DETAILS:3,ADD_CONTACT:4,ADD_PRODUCT:5}
      }
  },
  created(){
      this.customers=listCustomers();
      this.states = states;
  },
  methods:{
      listCustomers:function(){
          this.showscreen= this.screens.SEARCH_RESULTS;
      },
      newCustomer:function(){
          this.curCustomer = getCustomerTemplate();
          this.showscreen=this.screens.NEW_CUSTOMER;
      },
      showDetails:function(customer){
          this.curCustomer=customer;
          this.showscreen=this.screens.CUSTOMER_DETAILS;
      },
      gotoSearchResults:function(){
        this.showscreen= this.screens.SEARCH_RESULTS;
      },
      addContactToCustomer:function(){
        this.showscreen=this.screens.ADD_CONTACT;
      },
      cancelEventHandler:function(){
          this.showscreen=this.screens.CUSTOMER_DETAILS;
      },
      addCustomer:function(){
          addCustomer(this.curCustomer);
          this.curCustomer=getCustomerTemplate();
          this.gotoCustomerHome();
      },
      updateCustomerDetails:function(){
          this.listCustomers();
      },
      gotoCustomerHome:function(){
          this.showscreen=this.screens.CUSTOMER_HOME;
      }
  }
}
</script>
